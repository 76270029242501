import logo from '../logo.png';
import React, { Component } from 'react';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

class Admin extends Component {
    constructor(props) {
		super(props);
		this.API = process.env.REACT_APP_API_URL + 'admin.php';
		this.APP = process.env.REACT_APP_APP_URL;
		this.state = {
			user: '',
			username: '',
			password: '',
			urls: [],
		};
    }

    async getUrls() {
		let state = this.state;
		await axios
			.get(this.API + '?action=get_urls')
			.then(response => {
					if (response.status === 200 && response.data.status === 'success') {
							state.urls = response.data.response;
					}
			}).catch(error => console.log(error));
		this.setState(state);
    }

	componentDidMount() {
		let state = this.state;
		this.getUrls();
		let user = sessionStorage.getItem('user');
		if (user !== '' && user !== null) { 
			state.user = user;
		}else {
			state.user = '';
		}

		this.setState(state);
	}

	async deleteUrl(code) {
		let state = this.state;
		let urls = state.urls.filter(url => url.code !== code);
		state.urls = urls;
		this.setState(state);
		await axios
			.get(this.API + '?action=delete_url&code=' + code)
			.then(response => {
					if (response.status === 200 && response.data.status === 'success') {
							this.getUrls();
					}
			}).catch(error => console.log(error));
	}

	setUsername(username) {
		let state = this.state;
		state.username = username;
		this.setState(state);
	}

	setPassword(password) {
		let state = this.state;
		state.password = password;
		this.setState(state);
	}

	async handleSubmit(e) {
		e.preventDefault();
		console.log(e);
		let state = this.state;

		await axios
			.post(this.API + '?action=login', {username: state.username, password: state.password})
			.then(response => {
				console.log(response);
				if (response.status === 200 && response.data.status === 'success') {
					sessionStorage.setItem('user', JSON.stringify(response.data.response));
					state.user = response.data.response;
					state.username = '';
					state.password = '';
				} else {
					state.user = '';
					state.username = '';
					state.password = '';
				}
			}).catch(error => console.log(error));
		this.setState(state);
	}

	logout() {
		let state = this.state;
		sessionStorage.removeItem('user');
		state.user = '';
		this.setState(state);
	}

    render() {
 		if (this.state.user === '') {
			return (
				<div className="App-admin">
					<header className="App-header">
							<img src={logo} className="App-logo" alt="logo" />
					</header>
					<main className="App-main">
						<form onSubmit={(e) => this.handleSubmit(e)} className="login-form">
							<label htmlFor="username">Username: </label>
							<input
								type="text"
								value={this.state.username}
								placeholder="enter a username"
								onChange={({ target }) => this.setUsername(target.value)}
							/>
							<div>
								<label htmlFor="password">password: </label>
								<input
									type="password"
									value={this.state.password}
									placeholder="enter a password"
									onChange={({ target }) => this.setPassword(target.value)}
								/>
							</div>
							<button type="submit">Login</button>
						</form>
					</main>
				</div>
			);
		}

		return (
			<div className="App-admin">
				<header className="App-header">
						<img src={logo} className="App-logo" alt="logo" />
				</header>

				<main className="App-main">
					<button onClick={ e => this.logout() }>Logout</button>
					<ul className="list">
						{this.state.urls.map((url, index) => {
							return (
								<li key={index} className="list__item">
									<div className="card">
										<a href={this.APP + url.code} target="_blank" rel="noreferrer" className="card__shortcode">{url.code}</a>
										<span className="card__url">{url.url}</span>
										<span className="card__clicks">{url.clicks} {url.clicks > 1 ? 'clicks': 'click'}</span>
										<button className="card__button" onClick={() => this.deleteUrl(url.code)}><FontAwesomeIcon icon={faTrashCan}/></button>
									</div>
								</li>
							);
						})}
					</ul>
				</main>
			</div>
		);
    }
}

export default Admin;