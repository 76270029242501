import logo from '../logo.png';
import Input from '../components/Input';
import Shortener from '../components/Shortener';
import React, { Component } from 'react';
import axios from 'axios';
import ClipboardJS from 'clipboard';

class Home extends Component {

  constructor(props) {
    super(props);
    this.API = process.env.REACT_APP_API_URL;
    this.APP = process.env.REACT_APP_APP_URL;
    this.state = {
      url: '',
      appStoreUrl: '',
      playStoreUrl: '',
      shortenUrlID: '',
      copied: false
    };

    this.clipboard = new ClipboardJS('.clipboard__button');

    this.clipboard.on('success', (e) => this.copyToClipboard());
  }

  async handleChange(e) {
    let state = this.state;

    if (!!document.querySelector('#url-input-error')) {
      document.querySelector('#url-input-error').remove()
    }
    
    if (e.target.value.length > 0 && e.target.value.match(/https?:\/\/([\da-z.-]+)\.([a-z.]{2,6})([\w .-]*)*\/?/)) {
      if (e.target.name === 'url') {
        state.url = e.target.value;
      } else if (e.target.name === 'appStoreUrl') {
        state.appStoreUrl = e.target.value;
      } else if (e.target.name === 'playStoreUrl') {
        state.playStoreUrl = e.target.value;
      }
    } else {
      if (e.target.name === 'url') {
        state.url = '';
      } else if (e.target.name === 'appStoreUrl') {
        state.appStoreUrl = '';
      } else if (e.target.name === 'playStoreUrl') {
        state.playStoreUrl = '';
      }
      state.shortenUrlID = '';
      state.copied = false;
    }

    this.setState(state);
  }

  async handleClick() {
    let state = this.state;

    if (state.url === '') {
      //alert('Need to pass an URL');
      document.querySelector('input#url').classList.add('invalid')
      let error = document.createElement('div')
      error.setAttribute('id', 'url-input-error')
      error.classList.add('input-error')
      error.innerHTML = 'Need to pass an URL'
      document.querySelector('input#url').after(error)
    }

    await axios
      .get(this.API + '?action=shorten&url=' + escape(state.url) + '&appstoreurl=' + escape(state.appStoreUrl) + '&playstoreurl=' + escape(state.playStoreUrl))
      .then(response => {
        if (response.status === 200 && response.data.status === 'success') {
          state.shortenUrlID = this.APP + response.data.response;
        }
      }).catch(error => console.log(error));
    this.setState(state);
  }

  toggleAdvancedOptions(e) {
    console.log(e)
    document.querySelector('.optional-urls').classList.toggle('open')
  }

  copyToClipboard() {
    let state = this.state;
    state.copied = true;
    this.setState(state);
    setTimeout(() => {
      state.copied = false;
      this.setState(state);
    }, 1000)
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
  
        <main className="App-main">
          <div className="form">
            <div className="form-inner">
              <Input 
                name="url" 
                label="URL to shorten" 
                type="text" 
                pattern="^https?://.+" 
                required={true} 
                placeholder="https://example.com"
                onChange={(e) => this.handleChange(e)}
                //onClick={() => this.handleClick()}
              />

              <div className="optional-checkbox">
                <input type="checkbox" id="optional-urls-checkbox" onChange={(e) => this.toggleAdvancedOptions(e)} /> <label htmlFor="optional-urls-checkbox">Advanced options</label>
              </div>

              <div className="optional-urls">
                <Input
                  name="appStoreUrl"
                  label="URL to shorten for iOS users (optional)"
                  type="text"
                  pattern="^https://?://.+"
                  required={false}
                  placeholder="https://apps.apple.com/app/"
                  onChange={(e) => this.handleChange(e)}
                />

                <Input
                  name="playStoreUrl"
                  label="URL to shorten for Android users (optional)"
                  type="text"
                  pattern="^https://?://.+"
                  required={false}
                  placeholder="https://play.google.com/store/"
                  onChange={(e) => this.handleChange(e)}
                />
              </div>

              
              <div className="form-item">
                <button className="form-item__button" onClick={() => this.handleClick()}>
                  Shorten
                </button>
              </div>
            </div>
            <Shortener shortenUrl={this.state.shortenUrlID} onClick={() => this.copyToClipboard()} copied={this.state.copied} />
          </div>
        </main>
      </div>
    );
  } 
}

export default Home;