import React, { Component } from 'react';

class Input extends Component {
    render() {
        return (
            <div className="form-item">
                <label htmlFor={this.props.name} className="form-item__label">{this.props.label} {this.props.required && '(*)'}</label>
                <div className="form-item__input">
                    <input 
                        className="form-item__field" 
                        type={this.props.type} 
                        id={this.props.name} 
                        name={this.props.name} 
                        placeholder={this.props.placeholder}
                        pattern={this.props.pattern}
                        required={this.props.required}
                        onChange={(e) => this.props.onChange(e)}
                    />
                </div>
            </div>
        );
    }
}

export default Input;