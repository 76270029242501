import { faCopy, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QRCodeCanvas } from 'qrcode.react';
import React, { Component } from 'react';

class Shortener extends Component {
    
    downloadQRCode() {
        let canvas = document.getElementById('qrcode__svg');
        let image = canvas.toDataURL("image/png");
        let link = document.createElement("a");

        link.download = `qrcode-${this.props.shortenUrl}.png`;
        link.href = image;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    render() {
        return (
            this.props.shortenUrl !== '' && (
                <>
                    <div className="clipboard">
                        <span className="clipboard__text" id="clipboard__text">{this.props.shortenUrl}</span>
                        <button className="clipboard__button" data-clipboard-action="copy" data-clipboard-target="#clipboard__text">
                            <FontAwesomeIcon icon={this.props.copied ? faCheckCircle : faCopy}/>
                        </button>
                    </div>

                    <div className="qrcode">
                        <div className="qrcode__visual">
                            <QRCodeCanvas 
                                id="qrcode__svg"
                                value={this.props.shortenUrl} 
                                size={512}
                            />
                        </div>
                        <button onClick={() => this.downloadQRCode()} className="qrcode__download">Download QR Code</button>
                    </div>
                </>
            )
        );
    }
}

export default Shortener;